import { FormInstance } from "sunday-component/node_modules/antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "antd";
import { TextBoxErrorText } from "../../Common/Form/TextBoxForm";
import PinInput from "../../PinInput";
import {
  ButtonTitle,
  Content,
  FormItem,
  InputRow,
  Modal,
  ModalBody,
  ModalTitle,
  ResendButton,
  ResendWait,
  SendOtpButton,
  Space,
  SubmitOtpButton,
} from "./Otp.style";

export type OtpModalProp = {
  disable: boolean;
  otpRef: string;
  otpVerified: boolean;
  delay: number;
  onClickSend: Function;
  onClickSubmit: Function;
  target: string;
  form: FormInstance;
  isLoading: boolean;
  otpField: string;
  method: string;
};

export const OtpModal = ({
  disable,
  otpRef,
  otpVerified,
  delay,
  onClickSend,
  onClickSubmit,
  target,
  form,
  isLoading,
  otpField,
  method,
}: OtpModalProp) => {
  const { t } = useTranslation("otp-form");
  const [isVisible, setIsvisible] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [isCount, setIsCount] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  useEffect(() => {
    if (isCount) {
      if (count === 0) {
        setCount(0);
        setIsCount(false);
      }

      const timer = setInterval(() => {
        /** ternary condition for the count == 0 and isCount === false */
        setCount(count <= 0 ? delay - 1 : count - 1);
        clearInterval(timer);
      }, 1000);
    }
  }, [count, isCount, delay]);

  const sendOtp = async () => {
    form.resetFields([otpField]);
    await onClickSend(false);
    setIsvisible(true);
  };

  const resendOtp = () => {
    onClickSend(true);
    setCount(delay);
    setIsCount(true);
  };

  const onCancel = () => {
    setIsvisible(false);
  };

  const verifyOtp = () => {
    onClickSubmit();
    if (otpVerified) {
      setIsvisible(false);
    }
  };

  const validateOtp = (_, value, submitError) => {
    if (value && value.length < 6) {
      setDisableButton(true);
      return submitError(
        <TextBoxErrorText> {t("otpErrorRequired")}</TextBoxErrorText>
      );
    }
    setDisableButton(false);
    return submitError();
  };

  const timerForm = () => {
    const minutes =
      Math.floor(count / 60) < 10
        ? `0${Math.floor(count / 60)}`
        : `${Math.floor(count / 60)}`;
    const seconds = count % 60 < 10 ? `0${count % 60}` : `${count % 60}`;
    return `${minutes}:${seconds}`;
  };

  return (
    <>
      <SendOtpButton disabled={disable} onClick={sendOtp}>
        <ButtonTitle>{t("sendButton")}</ButtonTitle>
      </SendOtpButton>
      <Modal
        visible={isVisible}
        closable
        onCancel={onCancel}
        footer={null}
        width={444}
      >
        <Content>
          <ModalTitle>{t("title")}</ModalTitle>
          <ModalBody>
            {`${t("subtitle")} `}
            <strong>{target}</strong>
          </ModalBody>
          <InputRow justify="space-between" align="top">
            <Col>
              <FormItem
                name={otpField}
                rules={[
                  {
                    required: true,
                    message: (
                      <TextBoxErrorText>
                        {t("otpErrorRequired")}
                      </TextBoxErrorText>
                    ),
                  },
                  {
                    validator: validateOtp,
                  },
                ]}
                validateFirst
              >
                <PinInput
                  maxLength={6}
                  mode="pin"
                  disabled={false}
                  placeholder="●●●●●●"
                />
              </FormItem>
            </Col>
            <Col>
              <ModalBody>Ref : {otpRef}</ModalBody>
            </Col>
          </InputRow>

          <Space />
          <ModalBody>{t("resendBody")}</ModalBody>
          {!isCount ? (
            <ResendButton onClick={resendOtp}>{t("resendButton")}</ResendButton>
          ) : (
            <ResendWait>
              {t("waitResend")}
              {timerForm()}
            </ResendWait>
          )}
          <SubmitOtpButton
            onClick={verifyOtp}
            disabled={disableButton}
            loading={isLoading}
          >
            {t("submitButton")}
          </SubmitOtpButton>
        </Content>
      </Modal>
    </>
  );
};
