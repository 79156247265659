import styled from "styled-components";
import { PinInput as SundayPinInput } from "sunday-component";

const PinInput = styled(SundayPinInput)`
  &&& {
    caret-color: var(--ui-form-input-text-activated-fields_text-color);
  }
`;

export default PinInput;
