import styled from "styled-components";
import { Title } from "sunday-component";

const LinkTitle = styled(Title)`
  display: inline;
  color: var(--ui-form-button-text-enabled-text-color);

  &:hover {
    color: var(--ui-form-button-text-hover-text-color);
    text-decoration: var(--ui-form-button-text-hover-text-decoration);
    text-decoration-color: var(--ui-form-button-text-hover-text-color);
  }
  &:focus {
    color: var(--ui-form-button-text-focused-text-color);
    text-decoration: var(--ui-form-button-text-focused-text-decoration);
    text-decoration-color: var(--ui-form-button-text-focused-text-color);
  }
  &:disabled {
    color: var(--ui-form-button-text-disabled-text-color);
  }
`;

export default LinkTitle;
