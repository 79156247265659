import { Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import M from "antd/lib/modal/Modal";
import { color } from "src/styles/globals";
import styled from "styled-components";
import { Title as sundayTitle, Body as sundayBody } from "sunday-component";
import CommonButton from "./Button";

const Title = styled(sundayTitle).attrs({ level: 5 })`
  margin-top: 8px;
  text-align: center;
  color: ${color.charcoal10};
`;
const Body = styled(sundayBody).attrs({ level: 7 })`
  margin-top: 8px;
  text-align: center;
  color: ${color.charcoal10};
`;

const ButtonGroup = styled.div`
  margin-top: 16px;
`;

const Button = styled(CommonButton).attrs({
  fullWidth: true,
})`
  margin-top: 8px;
`;
const Modal = styled(M)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-header {
    border-radius: 12px;
  }
`;
const Image = styled.img`
  width: 56px;
`;
export type ErrorDialogProps = {
  title: string;
  description: string;
  errorButtons?: Array<{ text: string; onClick: Function; type?: string }>;
  isVisible?: boolean;
  icon?: JSX.Element;
};

const ErrorDialog = ({
  title,
  description,
  isVisible = true,
  errorButtons = [],
  icon,
}: ErrorDialogProps) => {
  const { sm } = useBreakpoint();
  return (
    <Modal
      width={sm ? "303px" : "auto"}
      visible={isVisible}
      footer={null}
      closable={false}
      data-testid="error-dialog-modal"
      data-gtm-input-code="error-dialog-modal"
    >
      <Row justify="center">
        {icon || <Image src="/static/common/x-circle.svg" />}
      </Row>
      <Title>{title}</Title>
      <Body>{description}</Body>
      {errorButtons.length > 0 && (
        <ButtonGroup>
          {errorButtons.map(({ text, onClick, type = "primary" }, index) => (
            <Button
              data-testid={`error-dialog-button-${index}`}
              data-gtm-input-code={`error-dialog-button-${index}`}
              onClick={onClick}
              type={type}
            >
              {text}
            </Button>
          ))}
        </ButtonGroup>
      )}
    </Modal>
  );
};
export default ErrorDialog;
