import styled from "styled-components";
import { Title, Headline, Body, FormItem as Fi } from "sunday-component";
import { Modal as M, Row } from "antd";
import { color } from "src/styles/globals";
import { CheckOutlined as Cl } from "@ant-design/icons";
import LinkTitle from "../../Common/LinkTitle";
import Button from "../../Common/Button";

export const CheckOutlined = styled(Cl)`
  color: #1c7c4e;
  font-weight: bold;
  font-size: 17px;
  line-height: 18px;
`;

export const Verified = styled(Title).attrs({ level: 9 })`
  margin-left: 10px;
  color: #1c7c4e;
`;

export const SendOtpButton = styled(Button)`
  max-height: 24px;
  width: 109px;
  padding: 0px;
`;

export const SubmitOtpButton = styled(Button)`
  max-height: 48px;
  width: 100%;
  margin-top: 40px;
  padding: 0px;
`;

export const FormItem = styled(Fi)`
  margin: 0px;
`;

export const Space = styled.div`
  margin-top: 122px;
`;

export const Modal = styled(M)`
  font-family: font-with-head-sc;
  top: 20px;
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-footer {
    padding: 16px;
  }
  .ant-modal-content {
    overflow: auto;
    border-radius: 16px;
  }
  .ant-scrolling-effect {
    width: 0px;
  }
`;

export const Content = styled.div`
  padding: 24px;
`;

export const ModalTitle = styled(Headline).attrs({ level: 5 })`
  margin-top: 40px;
  margin-bottom: 4px;
  color: ${color.charcoal10};
`;
export const ModalBody = styled(Body).attrs({ level: 7 })`
  margin-top: 2px;
  color: ${color.charcoal09};
`;

export const InputRow = styled(Row)`
  margin-top: 16px;
  max-height: 24px;
`;

export const ButtonTitle = styled(Title).attrs({ level: 9 })``;

export const ResendButton = styled(LinkTitle).attrs({ level: 7 })`
  margin-top: 4px;
  cursor: pointer;
`;

export const ResendWait = styled(Title).attrs({ level: 7 })`
  color: ${color.coolGreyCG};
  margin-top: 4px;
`;
