import { axiosErrorUnwrap } from "src/utils/axiosError";
import { clientHttpInstance } from "./http-client";

export type SendOtpRes = {
  referenceNumber: string;
};

export type VerifyOtpRes = {
  isValid: boolean;
  isOtpExpired: boolean;
};

/**
 * send message to verify mobile number or email address
 * @param target mobile phone number or email address
 * @param lang message language
 * @param method otp method => ['SMS', 'email']
 * @returns reference number otp
 */
export const sendOtp = async (
  target: string,
  lang: string,
  method: string,
  channelCode?: string
): Promise<SendOtpRes> => {
  try {
    const res = await clientHttpInstance.post<any>(
      "/otp/send-otp",
      { target, method, channelCode },
      {
        headers: {
          "Accept-Language": lang,
        },
      }
    );
    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};

/**
 * verify otp by reference number
 * @param otpNo otp number
 * @param referenceNo reference number for otp
 * @returns verify result
 */
export const validateOtp = async (
  otpNo: string,
  referenceNo: string
): Promise<VerifyOtpRes> => {
  try {
    const res = await clientHttpInstance.post<any>("/otp/validate-otp", {
      otpNo,
      referenceNo,
    });
    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};
